import { DropdownItem } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';

import { OrgRoles } from '@src/models/role';

import { useRBAC } from './useRBAC';

/**
 * Custom hook for populating the dropdown menu with all orgRoles.
 *
 * @param includeServiceUserRoles - when true it would include roles exclusive to service users in the list returned.
 * @returns list of role dropdown items
 */
export const useRoleDropdownOptions = (includeServiceUserRoles?: boolean) => {
  // i18n
  const { t } = useTranslation();
  const rolesTranslations = t('ACCOUNT_SETTINGS').SERVICE_USERS.ROLES;
  // Context
  const canAssignAdministrator = useRBAC('assignAdministrator');

  const roleItems: DropdownItem<OrgRoles>[] = [
    {
      label: rolesTranslations.MEMBER,
      id: 'member',
      value: 'member',
    },
    {
      label: rolesTranslations.ARTEFACT_CONTRIBUTOR,
      id: 'artefactContributor',
      value: 'artefactContributor',
    },
    {
      label: rolesTranslations.MANAGER,
      id: 'manager',
      value: 'manager',
    },
    {
      label: rolesTranslations.ADMINISTRATOR,
      id: 'administrator',
      value: 'administrator',
      hideFromList: !canAssignAdministrator,
    },
  ];

  // The 'artefactContributor' role is exclusive to service-users alone.
  return includeServiceUserRoles
    ? roleItems
    : roleItems.filter(({ value }) => value !== 'artefactContributor');
};
