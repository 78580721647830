import { Button } from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRBAC } from '@src/hooks/useRBAC';
import { EnvironmentType } from '@src/models/environment-type';
import { EnvTypeRoles, Role } from '@src/models/role';

import ManageDeployersModal from './ManageDeployersModal';

interface DeployersButtonProps {
  envType: EnvironmentType;
  roles: Role<EnvTypeRoles>[];
}

const DeployersButton = ({ envType, roles }: DeployersButtonProps) => {
  // Component state
  const [manageDeployersModalOpen, setManageDeployersModalOpen] = useState(false);
  // i18n
  const { t } = useTranslation();
  const translations = t('MANAGE_ENV_TYPES');

  // RBAC
  const canAddDeployer = useRBAC('addDeployer');

  const openDeployersModal = () => {
    setManageDeployersModalOpen(true);
  };

  return (
    <>
      <Button variant={'secondary'} size={'small'} onClick={openDeployersModal}>
        {roles.length
          ? `${roles.length} ${roles.length > 1 ? 'deployers' : 'deployer'}`
          : canAddDeployer
            ? translations.ADD_DEPLOYERS
            : translations.NO_DEPLOYERS_AVAILABLE}
      </Button>
      {manageDeployersModalOpen && (
        <ManageDeployersModal
          envTypeRoles={roles}
          openState={[manageDeployersModalOpen, setManageDeployersModalOpen]}
          envType={envType}
        />
      )}
    </>
  );
};

export default DeployersButton;
