import {
  Accordion,
  Button,
  EmptyStateCard,
  ErrorWarning,
  ExpandableCard,
  Icon,
  Spinner,
  WalCard,
  WalModal,
} from '@humanitec/ui-components';
import { ReactNode, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import SectionHeader from '@src/components/shared/SectionHeader';
import ResourceDependencyGraph from '@src/containers/Orgs/Apps/containers/App/containers/DeploymentAndDeltaCommon/components/ViewDeploymentOrDeltaTabs/components/ResourceDependencyGraph/ResourceDependencyGraph';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import useResourceDefinitionsQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionsQuery';
import useCheckConnectivityMutation, {
  TestConnectivityConditionStatus,
} from '@src/hooks/react-query/runtime-actions/useCheckConnectivityQuery';
import { useRBAC } from '@src/hooks/useRBAC';
import i18n from '@src/i18n/i18n';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';

import ResourcesTable from '../ResourcesTable';

const Grid = styled.div`
  display: grid;
  width: 50%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${units.padding.sm};
  margin-bottom: ${units.margin.md};
`;

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const translations = i18n.t('COMPONENTS').TEST_CLUSTER_MODAL.TEST_RESULTS;

const mapStatusToReadable = (type: string): { title: string; description?: ReactNode } => {
  switch (type) {
    case 'GraphResolvable':
      return {
        title: translations.GRAPH_RESOLVABLE_TITLE,
        description: (
          <Trans defaults={translations.GRAPH_RESOLVABLE_DESCRIPTION}>
            <Link
              target={'_blank'}
              className={'txt-base'}
              rel={'noopener noreferrer'}
              to={'https://developer.humanitec.com/platform-orchestrator/resources/resource-graph/'}
            />
          </Trans>
        ),
      };
    case 'GraphCanProvision':
      return {
        title: translations.GRAPH_CAN_PROVISION_TITLE,
        description: translations.GRAPH_CAN_PROVISION_DESCRIPTION,
      };
    case 'AgentCanConnect':
      return {
        title: translations.AGENT_CONNECTED_TITLE,
        description: (
          <Trans defaults={translations.AGENT_CONNECTED_DESCRIPTION}>
            <Link
              target={'_blank'}
              className={'txt-base'}
              rel={'noopener noreferrer'}
              to={
                'https://developer.humanitec.com/integration-and-extensions/humanitec-agent/overview/'
              }
            />
          </Trans>
        ),
      };
    case 'ClusterCanConnect':
      return {
        title: translations.CLUSTER_CAN_CONNECT_TITLE,
        description: translations.CLUSTER_CAN_CONNECT_DESCRIPTION,
      };
    case 'Authorized':
      return {
        title: translations.AUTHORIZED_TITLE,
        description: translations.AUTHORIZED_DESCRIPTION,
      };
    case 'OperatorModeEnabled':
      return {
        title: translations.OPERATOR_MODE_ENABLED_TITLE,
        description: translations.OPERATOR_MODE_ENABLED_DESCRIPTION,
      };
    case 'OperatorInstalled':
      return {
        title: translations.OPERATOR_INSTALLED_TITLE,
        description: (
          <Trans defaults={translations.OPERATOR_INSTALLED_DESCRIPTION}>
            <Link
              target={'_blank'}
              className={'txt-base'}
              rel={'noopener noreferrer'}
              to={
                'https://developer.humanitec.com/integration-and-extensions/humanitec-operator/overview/'
              }
            />
          </Trans>
        ),
      };
    default:
      return {
        title: type,
      };
  }
};

const Section = ({
  header,
  value,
  className,
}: {
  header: string;
  value: ReactNode;
  className?: string;
}) => (
  <div className={className}>
    <SectionHeader backgroundColor={'transparent'} sticky={false}>
      {header}
    </SectionHeader>
    <span className={'txt-base'}>{value}</span>
  </div>
);

const ConditionStatus = ({ status }: { status: TestConnectivityConditionStatus }) => {
  return (
    <div className={'flex'}>
      <Icon
        overrideColor={status === 'True' ? 'green' : status === 'False' ? 'alert' : 'text'}
        name={status === 'True' ? 'checkmark' : status === 'False' ? 'cross' : 'question'}
        marginRight={'md'}
      />
      {status === 'True' ? 'Pass' : status === 'False' ? 'Fail' : 'Unknown'}
    </div>
  );
};

export const TestClusterModal = () => {
  // Component state
  const [open, setOpen] = useState(false);

  // i18n
  const { t } = useTranslation();
  const modalTranslations = t('COMPONENTS').TEST_CLUSTER_MODAL;

  // Router hooks
  const { appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  const canTestCluster = useRBAC('testEditDeleteCloudAccounts');

  // React Query
  const {
    mutate: checkConnectivity,
    isSuccess: connectivityCheckIsSuccess,
    isError: connectivityCheckIsError,
    data: checkConnectivityData,
    isPending: connectivityCheckPending,
    error: connectivityCheckError,
    reset,
  } = useCheckConnectivityMutation();
  const { data: environment } = useEnvironmentQuery();
  const { data: resourceDefinitions } = useResourceDefinitionsQuery();

  const hasResponse = connectivityCheckIsError || connectivityCheckIsSuccess;

  if (!canTestCluster) {
    return <></>;
  }

  return (
    <>
      <Button
        iconLeft={'cloud-accounts'}
        variant={'secondary'}
        size={'medium'}
        className={'ml-sm'}
        onClick={() => setOpen(true)}>
        {modalTranslations.TEST_CLUSTER_CONNECTION}
      </Button>

      <WalModal
        openState={[open, setOpen]}
        size={'large'}
        backgroundColor={'base'}
        title={modalTranslations.TEST_CLUSTER_CONNECTION}
        content={
          <div>
            <Grid>
              <Section header={modalTranslations.APPLICATION_ID} value={appId} />
              <Section header={modalTranslations.ENVIRONMENT_ID} value={envId} />
              <Section
                header={modalTranslations.ENVIRONMENT_TYPE}
                value={environment?.type ?? ''}
              />
            </Grid>
            {!hasResponse && !connectivityCheckPending ? (
              <>
                <span className={'mb-md'}>
                  {modalTranslations.DESCRIPTION_1_PART_1}
                  <Link
                    target={'_blank'}
                    to={
                      'https://developer.humanitec.com/platform-orchestrator/resources/resource-definitions/#matching-criteria'
                    }>
                    {modalTranslations.MATCHING_CRITERIA}
                  </Link>
                  {modalTranslations.DESCRIPTION_1_PART_2}
                </span>
                <div className={'my-md'}>{modalTranslations.DESCRIPTION_2}</div>
              </>
            ) : connectivityCheckPending ? (
              <EmptyStateCard>
                <div className={'flex'}>
                  <Spinner size={'small'} className={'mr-sm'} />
                  {modalTranslations.TESTING_CONNECTION}
                </div>
              </EmptyStateCard>
            ) : hasResponse && checkConnectivityData ? (
              <>
                <Accordion
                  items={checkConnectivityData.data.conditions.map((condition) => ({
                    headerContent: (
                      <AccordionHeader>
                        {mapStatusToReadable(condition.type).title}
                        <ConditionStatus status={condition.status} />
                      </AccordionHeader>
                    ),
                    id: condition.status,
                    content: (
                      <div>
                        {mapStatusToReadable(condition.type).description && (
                          <Section
                            header={'Description'}
                            value={mapStatusToReadable(condition.type).description}
                            className={'mb-md'}
                          />
                        )}
                        <Section
                          header={'Status'}
                          value={<ConditionStatus status={condition.status} />}
                          className={'mb-md'}
                        />
                        <SectionHeader backgroundColor={'transparent'} sticky={false}>
                          Details
                        </SectionHeader>
                        <WalCard padding={'small'} cardStyle={'transparent'}>
                          {condition.message}
                        </WalCard>
                      </div>
                    ),
                    cardStyle: 'default',
                  }))}
                />
                <ExpandableCard
                  id={'card'}
                  className={'my-md'}
                  cardStyle={'transparent'}
                  headerContent={modalTranslations.CLUSTER_GRAPH}
                  content={
                    <>
                      <div className={'mb-md txt-sm'}>
                        {modalTranslations.CLUSTER_GRAPH_DESCRIPTION}
                      </div>
                      <ResourceDependencyGraph
                        customResourceDependencyGraph={{
                          nodes: checkConnectivityData.data.resource_summaries.map((node) => ({
                            ...node,
                            guresid: (node as any)?.gu_res_id,
                          })),
                        }}
                      />
                    </>
                  }
                />
                <ExpandableCard
                  id={'card'}
                  className={'my-md'}
                  cardStyle={'transparent'}
                  headerContent={modalTranslations.CLUSTER_DEFINITIONS_USED}
                  content={
                    <>
                      <ResourcesTable
                        hideFilters
                        readonly
                        resourceDefinitions={
                          resourceDefinitions?.filter((res) => {
                            const resSummaryIds = checkConnectivityData.data.resource_summaries.map(
                              (summmary) => summmary.def_id
                            );
                            return resSummaryIds.includes(res.id);
                          }) ?? []
                        }
                      />
                    </>
                  }
                />
              </>
            ) : (
              connectivityCheckError && (
                <ErrorWarning
                  code={connectivityCheckError.response?.data.error}
                  message={connectivityCheckError.response?.data.message}
                />
              )
            )}
          </div>
        }
        actions={{
          cancel: {
            text: modalTranslations.CLOSE,
            props: {
              onClick: () => {
                setOpen(false);
                reset();
              },
            },
          },
          main: {
            text: hasResponse ? modalTranslations.TEST_AGAIN : modalTranslations.TEST,
            props: {
              loading: connectivityCheckPending,
              type: 'submit',
              onClick: () => {
                if (environment?.type && !hasResponse) {
                  checkConnectivity({ app_id: appId, env_id: envId, env_type: environment?.type });
                } else if (hasResponse) {
                  reset();
                }
              },
            },
          },
        }}
      />
    </>
  );
};
