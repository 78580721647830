import {
  Button,
  EmptyStateCard,
  Icon,
  IconNames,
  WalTable,
  WalTableColumn,
  WalTableRow,
  WarningSection,
} from '@humanitec/ui-components';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import ConfirmDeleteModal from '@src/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import AddAccountModal from '@src/containers/Orgs/Accounts/components/AddAccountModal/AddAccountModal';
import { SectionTopBarSearchField } from '@src/containers/Orgs/components/SectionTopBarWrapper';
import SectionsSettingWrapper from '@src/containers/Orgs/components/styles';
import ResourceIcon from '@src/containers/Orgs/Resources/components/ResourceIcon/ResourceIcon';
import useAccountDeleteMutation from '@src/hooks/react-query/resource-accounts/mutations/useAccountDeleteMutation';
import useResourceAccountsQuery from '@src/hooks/react-query/resource-accounts/queries/useResourceAccountsQuery';
import { useDecision } from '@src/hooks/useDecision';
import { useRBAC } from '@src/hooks/useRBAC';
import { useResourcesStore } from '@src/hooks/zustand/useResourcesStore';
import { ResourceAccount, ResourceAccountType } from '@src/models/resource-account';
import { ResourceDefinition } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { generateResourceDefinitionUrl } from '@src/utilities/navigation';

import { TestAccountModal } from './components/TestAccountModal/TestAccountModal';

export interface AccountOption {
  name: string;
  id: ResourceAccountType;
  icon: IconNames;
  testingId?: string;
  onClick: () => void;
  hide?: boolean;
}

const AccountsTable = styled(WalTable)`
  margin-bottom: ${units.margin.md};
`;

const ResDefNameWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Wrapper = styled.div`
  display: flex;
  position: sticky;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${units.padding.xxl};
`;

export const getResourceAccountTypeText = (
  type: ResourceAccountType,
  translations: any
): string => {
  switch (type) {
    case 'aws':
      return translations.AWS;
    case 'aws-role':
      return translations.AWS_ROLE;
    case 'gcp':
      return translations.GCP;
    case 'gcp-identity':
      return translations.GCP_WORKLOAD_IDENTITY;
    case 'azure':
      return translations.AZURE;
    case 'azure-identity':
      return translations.AZURE_IDENTITY;
    case 'aiven':
      return translations.AIVEN;
    case 'cloudflare':
      return translations.CLOUDFLARE;
    default:
      return '';
  }
};

const Accounts = () => {
  // React Query
  const { data: resourceAccounts = [], isFetched: accountsLoaded } = useResourceAccountsQuery();
  const {
    mutate: deleteAccount,
    error: deleteAccountError,
    isSuccess: accountDeleted,
    reset: resetDeleteAccount,
  } = useAccountDeleteMutation();
  // Component state
  const [accountModalOpen, setAccountModalOpen] = useState<boolean>(false);
  const [accountTypeToAdd, setAccountTypeToAdd] = useState<ResourceAccountType | undefined>();
  const [editingAccount, setEditingAccount] = useState<ResourceAccount | undefined>();
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState<boolean>(false);
  const [accountToDelete, setAccountToDelete] = useState<ResourceAccount | undefined>();
  const [accountsFilterString, setAccountsFilterString] = useState<string>('');

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const accountsTranslations = t('ACCOUNT_SETTINGS').ACCOUNTS;

  // Zustand
  const { lastCreatedResourceAccountIds } = useResourcesStore();

  // context
  const canTestEditDeleteCloudAccounts = useRBAC('testEditDeleteCloudAccounts');

  // router
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  // optimizely
  const [deleteAccountDecision] = useDecision('delete_account');

  useEffect(() => {
    if (accountDeleted) {
      setConfirmDeleteModalOpen(false);
    }
  }, [accountDeleted]);

  useEffect(() => {
    if (confirmDeleteModalOpen) {
      resetDeleteAccount();
    }
  }, [confirmDeleteModalOpen, resetDeleteAccount]);

  const filteredAccounts = useMemo(() => {
    return resourceAccounts.filter((resourceAccount) =>
      resourceAccount.name.toLowerCase().includes(accountsFilterString.toLowerCase())
    );
  }, [accountsFilterString, resourceAccounts]);

  const editAccount = (account: ResourceAccount) => {
    setEditingAccount(account);
    setAccountModalOpen(true);
  };

  const handleDeleteButtonClick = (account: ResourceAccount) => {
    setAccountToDelete(account);
    setConfirmDeleteModalOpen(true);
  };

  const accountColumns: WalTableColumn<ResourceAccount>[] = [
    {
      label: accountsTranslations.ACCOUNT_NAME,
      prop: 'name',
      template: (row) => {
        const accountType =
          row.data.type === 'aws-role'
            ? 'aws'
            : row.data.type === 'gcp-identity'
              ? 'gcp'
              : row.data.type === 'azure-identity'
                ? 'azure'
                : row.data.type;
        return (
          <span className={'flex-centered'}>
            <Icon size={20} name={accountType} marginRight={'md'} />
            {row.data.name}
          </span>
        );
      },
    },
    {
      label: accountsTranslations.ACCOUNT_TYPE,
      prop: 'type',
      template: (row) => (
        <span>{getResourceAccountTypeText(row.data.type, accountsTranslations)}</span>
      ),
    },
    {
      label: accountsTranslations.IN_USE,
      prop: 'is_used',
      justifyContent: 'center',
      template: (row) => (row.data.is_used ? <Icon name={'checkmark'} /> : <span>-</span>),
    },
    {
      label: accountsTranslations.CREATED_AT,
      prop: 'created_at',
      template: (row) => (
        <span>
          {row.data.is_default
            ? '...'
            : formatDate(row.data.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE)}
        </span>
      ),
    },
    {
      prop: 'actions',
      hide: !canTestEditDeleteCloudAccounts,
      template: (row) =>
        !row.data.is_default && (
          <>
            <TestAccountModal resourceAccountId={row.data.id} />
            <Button
              iconLeft={'edit'}
              className={'mr-sm'}
              onClick={() => editAccount(row.data)}
              size={'small'}
              variant={'secondary'}
              ariaLabel={uiTranslations.EDIT}
            />
            {deleteAccountDecision.enabled && (
              <Button
                iconLeft={'delete'}
                onClick={() => handleDeleteButtonClick(row.data)}
                size={'small'}
                variant={'secondary'}
                ariaLabel={uiTranslations.DELETE}
              />
            )}
          </>
        ),
    },
  ];

  const handleAccountOptionClick = (id: ResourceAccountType) => {
    setAccountTypeToAdd(id);
    setAccountModalOpen(true);
  };

  const onDeleteAccountConfirmed = () => {
    if (accountToDelete) {
      deleteAccount(accountToDelete.id);
    }
  };

  const accountOptions: AccountOption[] = [
    {
      name: accountsTranslations.GCP,
      id: 'gcp',
      icon: 'gcp',
      testingId: 'qa-gcp-button',
      onClick: () => handleAccountOptionClick('gcp'),
    },
    {
      name: accountsTranslations.GCP_WORKLOAD_IDENTITY,
      id: 'gcp-identity',
      icon: 'gcp',
      onClick: () => handleAccountOptionClick('gcp-identity'),
    },
    {
      name: accountsTranslations.AWS,
      id: 'aws',
      icon: 'aws',
      onClick: () => handleAccountOptionClick('aws'),
    },
    {
      name: accountsTranslations.AWS_ROLE,
      id: 'aws-role',
      icon: 'aws',
      onClick: () => handleAccountOptionClick('aws-role'),
    },
    {
      name: accountsTranslations.AIVEN,
      id: 'aiven',
      icon: 'aiven',
      onClick: () => handleAccountOptionClick('aiven'),
    },
    {
      name: accountsTranslations.AZURE,
      id: 'azure',
      icon: 'azure',
      onClick: () => handleAccountOptionClick('azure'),
    },
    {
      name: accountsTranslations.AZURE_IDENTITY,
      id: 'azure-identity',
      icon: 'azure',
      onClick: () => handleAccountOptionClick('azure-identity'),
    },
    {
      name: accountsTranslations.CLOUDFLARE,
      id: 'cloudflare',
      icon: 'cloudflare',
      onClick: () => handleAccountOptionClick('cloudflare'),
    },
  ];

  const resourceDefinitionsInUseTableColumns: WalTableColumn<ResourceDefinition>[] = [
    {
      label: accountsTranslations.DEFINITION_ID,
      prop: 'id',
      alignIconAndText: true,
      template: (row) => (
        <div className={'flex-centered'}>
          <ResourceIcon hasMargin type={row.data.type} isDefaultResource={row.data.is_default} />
          <Link target={'_blank'} to={generateResourceDefinitionUrl(orgId, row.data.id)}>
            {row.data.id}
          </Link>
        </div>
      ),
    },
    {
      label: accountsTranslations.TYPE,
      prop: 'type',
      ellipsisTooltip: {
        maxWidth: 200,
        maxCharacters: 60,
        text: (row) => row.data.type?.replace(/(.{1,80})/g, '$1\u00ad'),
      },
    },
    {
      label: accountsTranslations.DRIVER,
      prop: 'driver_account',
      ellipsisTooltip: {
        maxWidth: 200,
        maxCharacters: 60,
        text: (row) => row.data.driver_account?.replace(/(.{1,80})/g, '$1\u00ad'),
      },
    },
    {
      label: accountsTranslations.ACTIVE_RESOURCES,
      prop: 'active_resources',
      fixedWidth: 100,
      justifyContent: 'flex-start',
      template: () => (
        <span>
          {
            deleteAccountError?.response?.data.details?.referencing_resources?.active_resources
              ?.length
          }
        </span>
      ),
    },
  ];

  return (
    <SectionsSettingWrapper
      rightContent={
        resourceAccounts.length > 1 && (
          <SectionTopBarSearchField
            name={'filter-accounts'}
            placeholder={accountsTranslations.ACCOUNTS_FILTER_PLACEHOLDER}
            onChange={setAccountsFilterString}
          />
        )
      }>
      <Wrapper>
        <Button iconLeft={'plus'} onClick={() => setAccountModalOpen(true)}>
          {accountsTranslations.ADD_ACCOUNT}
        </Button>
      </Wrapper>

      {filteredAccounts.length === 0 && accountsLoaded ? (
        <EmptyStateCard>{accountsTranslations.NO_CLOUD_ACCOUNTS}</EmptyStateCard>
      ) : (
        <AccountsTable
          caption={accountsTranslations.TABLE_CAPTION}
          columns={accountColumns}
          disableScrolling
          rows={
            filteredAccounts
              ? filteredAccounts?.map(
                  (resourceAccount): WalTableRow => ({
                    data: resourceAccount,
                    isNew: lastCreatedResourceAccountIds.includes(resourceAccount.id),
                  })
                )
              : []
          }
          showCount
        />
      )}
      {accountModalOpen && (
        <AddAccountModal
          isAccountPage
          accountOptions={accountOptions.filter((accountOption) => !accountOption.hide)}
          className={'qa-provider-modal'}
          key={editingAccount?.id}
          editingAccountState={[editingAccount, setEditingAccount]}
          openState={[accountModalOpen, setAccountModalOpen]}
          accountType={editingAccount ? editingAccount.type : accountTypeToAdd}
        />
      )}
      {confirmDeleteModalOpen && accountToDelete && (
        <ConfirmDeleteModal
          state={[confirmDeleteModalOpen, setConfirmDeleteModalOpen]}
          deleteConfirmedCallback={onDeleteAccountConfirmed}
          modalSize={'large'}
          customContentComponent={
            <div className={'mb-lg'}>
              <p className={'mb-md'}>
                <Trans
                  defaults={accountsTranslations.DELETE_CONFIRMATION_MESSAGE_1}
                  values={{ cloudAccountName: accountToDelete.name }}
                />
              </p>
              <p>{accountsTranslations.DELETE_CONFIRMATION_MESSAGE_2}</p>
            </div>
          }
          disableConfirmDeleteButton={Boolean(deleteAccountError)}
          customWarningComponent={
            deleteAccountError?.response?.data.details ? (
              <WarningSection className={'mt-lg'}>
                <ResDefNameWrapper>
                  <Icon name={'warning'} marginRight={'sm'} overrideColor={'yellow'} />
                  {accountsTranslations.ACCOUNT_IN_USE_WARNING_TITLE}
                </ResDefNameWrapper>
                <WalTable
                  rows={
                    deleteAccountError.response.data.details.referencing_resources.resource_definitions?.map(
                      (def) => ({ data: def })
                    ) || []
                  }
                  columns={resourceDefinitionsInUseTableColumns}
                  caption={accountsTranslations.RESOURCE_DEFINITIONS_IN_USE_TABLE}
                  tableRowStyle={'base'}
                />
              </WarningSection>
            ) : undefined
          }
          title={`${uiTranslations.DELETE} ${accountToDelete.name}, ${accountsTranslations.CREATED_ON} ${formatDate(accountToDelete.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE)}`}
          deleteButtonText={accountsTranslations.DELETE_CLOUD_ACCOUNT}
          inputConfirmation={{
            correctValue: accountToDelete.name,
            objectName: accountsTranslations.ACCOUNT,
            propertyToType: 'name',
            placeholder: accountsTranslations.DELETE_CONFIRMATION_INPUT_PLACEHOLDER,
          }}
        />
      )}
    </SectionsSettingWrapper>
  );
};

export default Accounts;
