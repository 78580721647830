import { useEffect } from 'react';
interface HTMLDocumentTitle {
  title?: string;
}
const HTMLDocumentTitle = ({ title }: HTMLDocumentTitle) => {
  useEffect(() => {
    document.title = `${title ? `${title} | Humanitec` : 'Humanitec'}`;
  }, [title]);

  return null;
};

export default HTMLDocumentTitle;
