import { create } from 'zustand';

import { setUserPreferencereInLS } from '@src/utilities/local-storage';

export type Theme = 'dark' | 'light';
export type UserPreferences = {
  theme: Theme | null;
  openContactForm: boolean;
  showEnvsInAppList: boolean;
  sortAppsBy: 'create-date' | 'name';
};
interface userPreferencesStore extends UserPreferences {
  setTheme: (theme: Theme) => void;
  setOpenContactForm: (openContactForm: boolean) => void;
  syncLocalStorageToUserPreferences: (userPreferences: UserPreferences) => void;
  setShowEnvsInAppList: (showEnvsInAppList: boolean) => void;
  setSortAppsBy: (sortAppsBy: 'create-date' | 'name') => void;
}

export const useUserPreferencesStore = create<userPreferencesStore>((set, getState) => {
  return {
    theme: null,
    openContactForm: false,
    showEnvsInAppList: true,
    sortAppsBy: 'create-date',
    setTheme: (theme) =>
      set(() => {
        setUserPreferencereInLS({
          ...getState(),
          theme,
        });
        return {
          theme,
        };
      }),
    setOpenContactForm: (openContactForm) =>
      set(() => {
        setUserPreferencereInLS({
          ...getState(),
          openContactForm,
        });
        return {
          openContactForm,
        };
      }),
    setShowEnvsInAppList: (showEnvsInAppList) =>
      set(() => {
        setUserPreferencereInLS({
          ...getState(),
          showEnvsInAppList,
        });
        return {
          showEnvsInAppList,
        };
      }),
    setSortAppsBy: (sortAppsBy) =>
      set(() => {
        setUserPreferencereInLS({
          ...getState(),
          sortAppsBy,
        });
        return {
          sortAppsBy,
        };
      }),
    syncLocalStorageToUserPreferences: (userPreferences) => {
      set(() => ({
        ...userPreferences,
      }));
    },
  };
});
