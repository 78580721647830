import {
  EmptyStateCard,
  InfoPopup,
  QuickDelete,
  WalTable,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import useRoleDeleteMutation from '@src/hooks/react-query/roles/mutations/useRoleDeleteMutation';
import useGetInvitations from '@src/hooks/react-query/roles/queries/useGetInvitations';
import useOrgRolesQuery from '@src/hooks/react-query/roles/queries/useOrgRolesQuery';
import { useRBAC } from '@src/hooks/useRBAC';
import { OrgRoles, Role } from '@src/models/role';
import { Invitation } from '@src/models/user';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { capitalize } from '@src/utilities/string-utility';

import OrgRoleModal from '../../../components/role-modals/OrgRoleModal/OrgRoleModal';

const Name = styled.div`
  word-break: break-word;
  font-size: ${units.fontSize.sm};
`;

const Email = styled.div`
  font-size: ${units.fontSize.sm};
  word-break: break-word;
  display: flex;
`;

const Status = styled.div`
  font-size: ${units.fontSize.sm};
  display: flex;
`;

const RoleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${units.padding.xl};
  align-items: center;
`;

export const OrgMembersTable = ({ filterValue }: { filterValue: string }) => {
  // i18n
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');
  const orgSettingsTranslations = t('ORG_SETTINGS');
  const navigationTranslations = t('NAVIGATION');
  const uiTranslations = t('UI');

  // Context
  const canDeleteOrgMember = useRBAC('deleteOrgMember');

  // React Query
  const { data: usersData } = useOrgRolesQuery();

  const { mutate: deleteRole } = useRoleDeleteMutation();

  const users = useMemo(() => {
    return (
      usersData?.filter(
        (userData) => userData.type !== 'service' // filter service users by search term if one is inputted
      ) || []
    );
  }, [usersData]);

  const userRows: WalTableRow<Role<OrgRoles>>[] = useMemo(
    () =>
      users?.map(
        (u): WalTableRow => ({
          data: u,
        })
      ) ?? [],
    [users]
  );

  // Selectors
  const { data: invitations } = useGetInvitations();

  const removeUser = (id: string) => {
    deleteRole(id);
  };

  const formattedInvitations = useMemo(() => {
    // Parses data from the invitations into a hasmap with a key of user_id and value of expiry date timestamp
    const invitationsMap: { [key: string]: string } = {};
    invitations?.forEach(
      (invitation: Invitation) => (invitationsMap[invitation.user_id] = invitation.expires_at)
    );
    return invitationsMap;
  }, [invitations]);

  const getExpiryDate = (id: string) =>
    formattedInvitations[id]
      ? formatDate(formattedInvitations[id], DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE)
      : ' _ ';

  const tableCols: WalTableColumn<Role<OrgRoles>>[] = [
    {
      label: 'Name',
      prop: 'name',
      fixedWidth: 300,
      template: (row) => {
        return (
          <Name>
            <Link to={`${row.data.id}/applications`}>
              {row.data.name.trim() || row.data.email || '...'}
            </Link>
          </Name>
        );
      },
    },
    {
      label: 'Email',
      prop: 'email',
      justifyContent: 'flex-start',
      fixedWidth: 300,
      template: (row) => <Email>{row.data.email}</Email>,
    },
    {
      label: authTranslations.STATUS,
      prop: 'active',
      justifyContent: 'flex-start',
      template: (row) => (
        <Status>
          {row.data.type === 'invitation' ? (
            <>
              {authTranslations.INVITE_PENDING}
              <InfoPopup
                text={`${authTranslations.INVITE_EXPIRES_AT} ${getExpiryDate(row.data.id)}`}
                position={'bottom'}
                width={150}
              />
            </>
          ) : (
            authTranslations.ACTIVE
          )}
        </Status>
      ),
    },
    {
      label: 'Role',
      prop: 'role',
      tabIndex: -1,
      template: (row) => (
        <RoleGrid>
          {capitalize(row.data.role)}
          <OrgRoleModal mode={{ name: 'change', user: row.data, buttonVariant: 'small' }} />
        </RoleGrid>
      ),
    },
    {
      prop: 'actions',
      tabIndex: -1,
      template: (row) => {
        return (
          canDeleteOrgMember &&
          // show delete if role belongs to non-admin OR there are more than one admin
          (row.data.role !== 'administrator' ||
            users.filter((u) => u.role === 'administrator').length > 1) && (
            <QuickDelete
              onConfirm={() => removeUser(row.data.id)}
              confirmationButtonText={orgSettingsTranslations.REMOVE_USER}
            />
          )
        );
      },
    },
  ];

  const filteredRows = userRows.filter(
    (row) =>
      row.data.email?.toLowerCase().includes(filterValue) ||
      row.data.name?.toLowerCase().includes(filterValue)
  );

  if (!filteredRows.length) {
    return <EmptyStateCard>{uiTranslations.NO_RESULTS_FOUND}</EmptyStateCard>;
  }

  return (
    <WalTable
      rows={filteredRows}
      caption={navigationTranslations.ORG_MEMBERS}
      columns={tableCols}
      showCount
    />
  );
};
