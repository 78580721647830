import { Button, MultistepModal, WalCard } from '@humanitec/ui-components';
import { Dispatch, SetStateAction, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import ReviewCloneDetails from '@src/components/shared/CloneModal/components/ReviewCloneDetails/ReviewCloneDetails';
import CreateEnvironmentForm from '@src/components/shared/CreateEnvironmentForm/CreateEnvironmentForm';
import useDeploymentDeltaCreateMutation from '@src/hooks/react-query/deployment-delta/mutations/useDeploymentDeltaCreateMutation';
import useEnvironmentCreateMutation from '@src/hooks/react-query/environments/mutations/useEnvironmentCreateMutation';
import useApplicationEnvironmentsQuery from '@src/hooks/react-query/environments/queries/useApplicationEnvironmentsQuery';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import { useRBAC } from '@src/hooks/useRBAC';
import { DeploymentDelta } from '@src/models/deployment-delta';
import { DeploymentObject } from '@src/models/deployment-object';
import { Environment } from '@src/models/environment';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

const EnvCard = styled(WalCard)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${units.margin.md};
`;

const EnvNameAndType = styled.div<{ $disabled?: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin: 0 ${units.margin.md};
  ${({ $disabled, theme }) =>
    $disabled &&
    css`
      color: ${theme.color.baseOutline};
    `}
`;

const EnvType = styled.span<{ $disabled?: boolean }>`
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.textTranslucent};
  ${({ $disabled, theme }) =>
    $disabled &&
    css`
      color: ${theme.color.baseOutline};
    `}
`;

interface CloneModalProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  fromDeployment?: DeploymentObject;
}

interface CreateEnvironmentFields {
  envId: string;
  envTypeId: string;
}

const CloneModal = ({ openState, fromDeployment }: CloneModalProps) => {
  // React Query
  const { mutate: createEnvironment, isPending: isEnvironmentCreating } =
    useEnvironmentCreateMutation();

  // Components state
  const [open, setOpen] = openState;
  // If 'Create new environment' is clicked, we replcae first step with create environment form screeen
  const [isSecondStepCreate, setIsSecondStepCreate] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [targetEnv, setTargetEnv] = useState<Environment>();
  const [cloneDraftContent, setCloneDraftContent] = useState<Partial<DeploymentDelta>>({});

  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const { mutate: createDeploymentDeltaMutation } = useDeploymentDeltaCreateMutation();
  const { data: applicationEnvironments = [] } = useApplicationEnvironmentsQuery();
  const { data: currentEnvironment } = useEnvironmentQuery();

  // i18n
  const { t } = useTranslation();
  const { t: tViewEnvironment } = useTranslation('viewEnvironment');
  const uiTranslations = t('UI');
  const modalTranslations = tViewEnvironment('DEPLOYS').DEPLOYMENT_CARD.CLONE_MODAL;

  // form for creating new environment
  const formMethods = useWalhallForm<CreateEnvironmentFields>();
  // RBAC
  const canCreateEnvironment = useRBAC('createEnvironment');

  const handleCreateEnvironment = (formData: CreateEnvironmentFields) => {
    if (isSecondStepCreate && orgId && appId && fromDeployment?.id) {
      createEnvironment({
        orgId,
        appId,
        envName: formData.envId,
        envType: formData.envTypeId,
        deploymentId: fromDeployment.id,
      });
    }
  };

  const createCloneDraft = async () => {
    if (targetEnv && fromDeployment) {
      createDeploymentDeltaMutation({
        envId: targetEnv?.id,
        fromDelta: cloneDraftContent,
        name: `Clone from ${fromDeployment.env_id} draft`,
        navigateAfterCreation: 'base-path',
      });
    }
  };

  return (
    <FormProvider {...formMethods}>
      {open && (
        <MultistepModal
          backgroundColor={'base'}
          openState={[open, setOpen]}
          currentStepState={[currentStep, setCurrentStep]}
          handleFormSubmit={handleCreateEnvironment}
          size={'large'}
          disableOverflow={isSecondStepCreate}
          steps={[
            isSecondStepCreate
              ? {
                  title: modalTranslations.CREATE_NEW_ENVIRONMENT,
                  subTitle: (
                    <Trans i18nKey={'VIEW_APPLICATION.PARTIAL_CLONE_MODAL.CREATE_ENV_SUB_HEADING'}>
                      <i />
                    </Trans>
                  ),
                  content: (
                    <div className={'flex gap-md mb-lg mt-lg'}>
                      <CreateEnvironmentForm defaultEnvTypeId={currentEnvironment?.type} />
                    </div>
                  ),
                  actions: [
                    {
                      label: uiTranslations.BACK,
                      disableDefaultAction: true,
                      callback: () => {
                        setCurrentStep(0);
                        setIsSecondStepCreate(false);
                      },
                    },
                    {
                      label: modalTranslations.CREATE_NEW_ENVIRONMENT,
                      disabled: isEnvironmentCreating,
                      variant: 'primary',
                      disableDefaultAction: true,
                      type: 'submit',
                    },
                  ],
                }
              : {
                  title:
                    currentStep === 0
                      ? modalTranslations.STEP1TITLE
                      : `${modalTranslations.STEP1TITLESELECTED} ${
                          targetEnv?.name || targetEnv?.id
                        }`,
                  content: (
                    <>
                      <div className={'txt-sm mb-md'}>
                        {modalTranslations.CREATE_NEW_ENVIRONMENT}
                      </div>
                      <div className={'txt-sm txt-translucent mb-md'}>
                        {modalTranslations.CREATE_NEW_ENVIRONMENT_INFO}
                      </div>

                      <EnvCard cardStyle={'default'}>
                        <EnvNameAndType>{modalTranslations.NEW_ENVIRONMENT}</EnvNameAndType>

                        <Button
                          size={'small'}
                          disabled={!canCreateEnvironment}
                          onClick={() => {
                            setIsSecondStepCreate(true);
                          }}>
                          {modalTranslations.CREATE_ENVIRONMENT}
                        </Button>
                      </EnvCard>
                      {applicationEnvironments.length > 0 && (
                        <>
                          <div className={'txt-sm mt-lg mb-md'}>
                            {modalTranslations.CLONE_TO_EXISTING}
                          </div>
                          <div className={'txt-sm txt-translucent mb-md'}>
                            {modalTranslations.CLONE_TO_EXISTING_INFO}
                          </div>
                        </>
                      )}
                      {applicationEnvironments.map((environment) => (
                        <EnvCard cardStyle={'default'} key={environment.id}>
                          <EnvNameAndType $disabled={environment.id === fromDeployment?.env_id}>
                            {environment.name}
                            <EnvType $disabled={environment.id === fromDeployment?.env_id}>
                              {modalTranslations.ENVIRONMENT_TYPE}: {environment.type}
                            </EnvType>
                          </EnvNameAndType>

                          <Button
                            size={'small'}
                            ariaLabel={`${uiTranslations.SELECT} ${environment.name}`}
                            disabled={environment.id === fromDeployment?.env_id}
                            onClick={() => {
                              setCurrentStep(1);
                              setTargetEnv(environment);
                            }}>
                            {uiTranslations.SELECT}
                          </Button>
                        </EnvCard>
                      ))}
                    </>
                  ),
                  actions: [
                    {
                      label: uiTranslations.CLOSE,
                      callback: () => setOpen(false),
                    },
                  ],
                },
            {
              title: modalTranslations.STEP2TITLE,
              subTitle: (
                <div className={'mb-md'}>
                  {modalTranslations.WORKLOADS_AND_RESOURCES_WITHOUT_CHANGES_WARNING}
                </div>
              ),
              content: (
                <ReviewCloneDetails
                  targetEnv={targetEnv}
                  fromDeployment={fromDeployment}
                  cloneDraftContentState={[cloneDraftContent, setCloneDraftContent]}
                />
              ),
              actions: [
                {
                  label: uiTranslations.BACK,
                  callback: () => setCurrentStep(0),
                },
                {
                  label: `${modalTranslations.CREATE_DRAFT_IN} ${targetEnv?.id}`,
                  variant: 'primary',
                  callback: createCloneDraft,
                },
              ],
            },
          ]}
        />
      )}
    </FormProvider>
  );
};

export default CloneModal;
