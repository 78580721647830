import {
  ContainerErrorState,
  ReplicaPodRuntime,
  RuntimeStatus,
  RuntimeStatusClass,
  WorkloadController,
} from '@src/models/environment';

export const REPLICA_SET_RUNTIME_STATUS_ORDER: Record<RuntimeStatus, number> = {
  Stopped: 1,
  Failed: 2,
  Starting: 3,
  'Partially suceeded': 3,
  Running: 4,
  Succeeded: 4,
  Unknown: 5,
};

export const sortReplicaSetsBySeverity = (replicaSets: WorkloadController[]) => {
  return replicaSets.sort(
    (a, b) =>
      REPLICA_SET_RUNTIME_STATUS_ORDER[a.status] - REPLICA_SET_RUNTIME_STATUS_ORDER[b.status]
  );
};
/**
 * Returns true if should show alert color.
 *
 * @returns boolean
 */
export const mapRuntimeStatusToAlertColor = (status?: RuntimeStatusClass) => {
  switch (status) {
    case 'Failure':
    case 'Warning':
      return true;
    default:
      return false;
  }
};

export const getContainerErrorsInReplicaSet = (
  replicaPodRuntime: ReplicaPodRuntime,
  errorsArr: ContainerErrorState[],
  /** We want to consider warnings as errors in some places. This is temporary until we improve the runtime error messaging. */
  showWarningsAsErrors: boolean = true
) => {
  replicaPodRuntime?.containerStatuses?.forEach((containerStatus) => {
    if (
      containerStatus.status === 'Failure' ||
      (showWarningsAsErrors && containerStatus.status === 'Warning')
    ) {
      if (containerStatus.state) {
        Object.values(containerStatus.state).forEach((state) => {
          errorsArr.push({
            pod: replicaPodRuntime.podName,
            container: containerStatus.name,
            reason: state?.reason,
            message: state?.message,
          });
        });
      }
    }
  });
};
