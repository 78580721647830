import {
  CardStyles,
  FilterByOption,
  FilterByOptions,
  FilterConditions,
  MultiFilterTable,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import { compact, uniq } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import DeleteResourceDefinitionModalWithButton from '@src/containers/Orgs/Resources/components/DeleteResourceDefinitionModalWithButton';
import ResourceIcon from '@src/containers/Orgs/Resources/components/ResourceIcon/ResourceIcon';
import { filterResourceDefinitions } from '@src/containers/Orgs/Resources/components/ResourcesTable/resource-filter-utils';
import useApplicationsQuery from '@src/hooks/react-query/applications/queries/useApplicationsListQuery';
import useEnvironmentTypesQuery from '@src/hooks/react-query/environment-types/queries/useEnvironmentTypesQuery';
import { useDecision } from '@src/hooks/useDecision';
import { useResourcesStore } from '@src/hooks/zustand/useResourcesStore';
import { ResourceDefinition } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { generateResourceDefinitionUrl } from '@src/utilities/navigation';

const NameWrapper = styled.div<{ alignIconAndText?: boolean }>`
  display: grid;
  grid-template-columns: auto minmax(auto, 100%);
  column-gap: ${units.padding.sm};
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  ${({ alignIconAndText }) =>
    alignIconAndText &&
    css`
      justify-content: space-between;
    `};
`;

const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
`;

const ExpandableContentHeader = styled.div`
  font-size: ${units.fontSize.sm};
  margin-bottom: ${units.margin.md};
`;

interface ResourcesTableProps {
  resourceDefinitions: ResourceDefinition[];
  hideFilters?: boolean;
  readonly?: boolean;
  maxDisplayedRows?: number;
  tableRowStyle?: CardStyles;
}

const ResourcesTable = ({
  resourceDefinitions,
  hideFilters,
  readonly,
  maxDisplayedRows = 50,
  tableRowStyle,
}: ResourcesTableProps) => {
  // i18n
  const { t } = useTranslation();
  const tableTranslations = t('ACCOUNT_SETTINGS').RESOURCES.TABLE;
  const filterTranslations = t('ACCOUNT_SETTINGS').RESOURCES.FILTER;
  const translations = t('RESOURCE_MATCHING');

  // optimizely
  const [resourceDefinitionNameClickableDecision] = useDecision(
    'resource_definition_row_clickable'
  );

  // React Query
  const { data: applications = [] } = useApplicationsQuery();
  const { data: envTypes = [] } = useEnvironmentTypesQuery();

  // Zustand
  const { lastCreatedResourceDefinitions } = useResourcesStore();

  // Component state
  const [filterConditions, setFilterConditions] = useState<FilterConditions>();

  // Router hooks
  const navigate = useNavigate();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const filteredResourceDefinitions = useMemo(() => {
    return resourceDefinitions
      ? filterResourceDefinitions(resourceDefinitions, filterConditions)
      : [];
  }, [resourceDefinitions, filterConditions]);

  const columns: WalTableColumn<ResourceDefinition>[] = [
    {
      label: tableTranslations.ID,
      prop: 'id',
      alignIconAndText: true,
      ellipsisTooltip: {
        maxWidth: 400,
        maxCharacters: 60,
        text: (row) => row.data.name.replace(/(.{1,80})/g, '$1\u00ad'),
      },
      infoPopupText: (row) => (row.data.is_default ? translations.DEFAULT_INFO : undefined),
      template: (row) => (
        <NameWrapper alignIconAndText={row.data.is_default}>
          <ResourceIcon type={row.data.type} isDefaultResource={row.data.is_default} />
          {resourceDefinitionNameClickableDecision.enabled ? (
            <Link className={'title-link'} to={generateResourceDefinitionUrl(orgId, row.data.id)}>
              {row.data.id}
            </Link>
          ) : (
            <span>{row.data.id}</span>
          )}
        </NameWrapper>
      ),
    },
    {
      label: tableTranslations.DRIVER,
      prop: 'driver_type',
      ellipsisTooltip: {
        maxWidth: 200,
        maxCharacters: 30,
        text: (row) => row.data.driver_type,
      },
      template: (row) => <p>{row.data.driver_type || '-'}</p>,
    },
    {
      label: tableTranslations.TYPE,
      prop: 'type',
      justifyContent: 'flex-start',
      ellipsisTooltip: {
        maxWidth: 150,
        maxCharacters: 30,
        text: (row) => row.data.type,
      },
      template: (row) => <p>{row.data.type || '-'}</p>,
    },
    {
      prop: 'actions',
      tabIndex: -1,
      hide: readonly,
      template: (row) => {
        return (
          <ActionsWrapper>
            <DeleteResourceDefinitionModalWithButton
              iconLeft={'delete'}
              size={'small'}
              variant={'secondary'}
              resourceDefinition={row.data}
            />
          </ActionsWrapper>
        );
      },
    },
  ];

  const resourceTypes = uniq(resourceDefinitions?.map((def) => def.type));
  const resourceDefinitionIds = uniq(resourceDefinitions.map((def) => def.id));
  const driverTypes = uniq(resourceDefinitions?.map((def) => def.driver_type));
  const envIds = uniq(applications?.flatMap((app) => app.envs).map((env) => env.id));
  const appIds = applications?.map((app) => app.id);
  const resIds = uniq(
    compact(
      resourceDefinitions.flatMap((resDef) =>
        resDef.criteria?.flatMap((criteria) => criteria.res_id)
      )
    )
  );
  const envTypeIds = envTypes?.map((envType) => envType.id);

  const detailsFilterByOptions: FilterByOption[] = [
    {
      label: filterTranslations.RESOURCE_TYPE,
      id: 'type',
      value: 'type',
      comboSelectOptions: resourceTypes.map((resourceType) => ({
        id: resourceType,
        label: resourceType,
        searchString: resourceType,
        value: resourceType,
      })),
    },
    {
      label: filterTranslations.DRIVER_TYPE,
      id: 'driver_type',
      value: 'driver_type',
      comboSelectOptions: driverTypes.map((driverType) => ({
        id: driverType,
        label: driverType,
        searchString: driverType,
        value: driverType,
      })),
    },
    {
      label: filterTranslations.DEFINITION_ID,
      id: 'id',
      value: 'id',
      comboSelectOptions: resourceDefinitionIds.map((id) => ({
        id,
        label: id,
        searchString: id,
        value: id,
      })),
    },
  ];

  const matchingCriteriaFilterByOptions: FilterByOption[] = [
    {
      label: filterTranslations.ENVIRONMENT_TYPE,
      id: 'env_type',
      value: 'env_type',
      comboSelectOptions: envTypeIds.map((environmentType) => ({
        id: environmentType,
        label: environmentType,
        searchString: environmentType,
        value: environmentType,
      })),
    },
    {
      label: filterTranslations.ENVIRONMENT_ID,
      id: 'env_id',
      value: 'env_id',
      comboSelectOptions: envIds.map((id) => ({ id, label: id, searchString: id, value: id })),
    },
    {
      label: filterTranslations.APPLICATION_ID,
      id: 'app_id',
      value: 'app_id',
      comboSelectOptions: appIds.map((id) => ({ id, label: id, searchString: id, value: id })),
    },
    {
      label: filterTranslations.RESOURCE_ID,
      id: 'res_id',
      value: 'res_id',
      comboSelectOptions: resIds.map((id) => ({ id, label: id, searchString: id, value: id })),
    },
    {
      label: filterTranslations.RESOURCE_CLASS,
      id: 'class',
      value: 'class',
    },
  ];

  const navigateToResourceDefinition = (
    _: React.MouseEvent,
    row: WalTableRow<ResourceDefinition>
  ) => {
    navigate(generateResourceDefinitionUrl(orgId, row.data.id));
  };

  const filterByOptions: FilterByOptions = {
    details: {
      title: filterTranslations.BY_DEFINITION_DETAILS,
      options: detailsFilterByOptions,
    },
    criteria: {
      title: filterTranslations.BY_MATCHING_CRITERIA,
      options: matchingCriteriaFilterByOptions,
    },
  };

  return (
    <>
      <MultiFilterTable
        showCount
        filterByOptions={filterByOptions}
        onFiltersChange={(conditions?: FilterConditions) => {
          setFilterConditions(conditions);
        }}
        hideFilters={hideFilters}
        caption={tableTranslations.CAPTION}
        columns={columns}
        disableScrolling
        noItemsTextFiltersApplied={translations.NO_MATCHING_RESOURCES}
        noItemsText={tableTranslations.NO_RESOURCES}
        initialRowsCount={resourceDefinitions.length}
        maxDisplayedRows={maxDisplayedRows}
        tableRowStyle={tableRowStyle}
        tableStyle={resourceDefinitionNameClickableDecision.enabled ? undefined : 'clickable'}
        rows={
          filteredResourceDefinitions
            ? filteredResourceDefinitions?.map(
                (r): WalTableRow => ({
                  data: r,
                  isNew: lastCreatedResourceDefinitions.includes(r.id),
                  onRowClick: resourceDefinitionNameClickableDecision.enabled
                    ? undefined
                    : navigateToResourceDefinition,
                  expandableContent: !r.is_default ? (
                    <div>
                      <ExpandableContentHeader>
                        {tableTranslations.MATCHING_CRITERIA}
                      </ExpandableContentHeader>
                    </div>
                  ) : undefined,
                })
              )
            : []
        }
      />
    </>
  );
};

export default ResourcesTable;
