import { ReactNode } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { RBACPermissionTypes, useRBAC } from '@src/hooks/useRBAC';
import { MatchParams } from '@src/models/routing';
import { generateAppURL, generateSettingsURL } from '@src/utilities/navigation';

interface PrivateRouteForOrgRoleProps {
  children: ReactNode;
  permissionName: RBACPermissionTypes;
  redirectUrl?: 'apps' | 'artefacts' | 'settings';
}

export const PrivateRouteForOrgRole = ({
  children,
  permissionName,
  redirectUrl,
}: PrivateRouteForOrgRoleProps) => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;
  const permitted = useRBAC(permissionName);

  const getRedirectURL = () => {
    if (redirectUrl === 'apps') {
      return generateAppURL(orgId);
    } else if (redirectUrl === 'artefacts') {
      return generateSettingsURL(orgId, 'artefacts');
    } else if (redirectUrl === 'settings') {
      return generateSettingsURL(orgId);
    }
  };

  const url = getRedirectURL();

  return permitted ? (
    <>{children}</>
  ) : url && permitted !== undefined ? (
    <Navigate to={url} />
  ) : (
    <></>
  );
};
